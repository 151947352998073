import React from 'react';

export default (props) => (
	<svg
		version="1.1"
		x="0px"
		y="0px"
		width="32px"
		height="32px"
		viewBox="0 0 32 32"
		enableBackground="new 0 0 32 32"
	>
		<g>
			<path
				className="track"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.954,29.273c0.434,0.443,1.48,0.651,2.152,0.172s1.143-0.994,1.477-1.203
				c0.335-0.206,0.332-0.291,0.621-0.356s0.834-0.61,1.24-0.712c0.405-0.102,1.259-0.937,1.259-0.937s-1.031,0.067-1.295,0.182
				c-0.265,0.114-1.056,0.442-1.223,0.492s-0.634,0.037-0.383-0.133c0.251-0.171,0.651-0.479,1.058-0.89
				c0.407-0.412,0.913-1.009,1.186-1.246c0.272-0.236,0.979-0.908,0.876-1.067c-0.104-0.158-0.533,0.146-0.912,0.313
				c-0.38,0.166-0.547,0.602-0.949,0.757c-0.401,0.154-1.067,0.828-1.258,0.935c-0.19,0.109-0.689,0.139-0.492,0.001
				c0.197-0.136,1.056-1.167,1.204-1.467s0.273-0.463,0.676-1.022c0.401-0.559,0.928-1.331,0.802-1.378s-0.454,0.159-0.931,0.536
				s-0.973,0.987-1.294,1.378c-0.323,0.391-1.061,1.622-1.222,1.689c-0.161,0.068,0.143-0.354,0.273-0.932s0.063-0.758,0.292-1.154
				c0.229-0.397,0.694-0.897,0.693-1.245c0-0.348-0.036-0.754-0.036-0.754s-0.912,0.942-1.205,1.468
				c-0.292,0.524-0.521,1.013-0.711,1.466c-0.189,0.454-0.122,0.954-0.292,1.154c-0.17,0.2-0.744,0.756-0.621,0.356
				c0.124-0.398,0.057-1.003,0.109-1.331s0.352-0.849,0.273-0.932c-0.078-0.084-0.854,0.508-1.094,1.333
				c-0.24,0.826-0.442,1.734-0.584,2.31C12.502,27.63,12.521,28.83,12.954,29.273"
			/>
			<path
				className="track"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.338,20.802c-0.472,0.084-1.231-0.135-1.451-0.569
				c-0.22-0.438-0.296-0.828-0.419-1.029c-0.123-0.203-0.084-0.242-0.241-0.361c-0.156-0.118-0.267-0.548-0.481-0.722
				c-0.215-0.172-0.396-0.834-0.396-0.834s0.63,0.345,0.749,0.481c0.119,0.134,0.482,0.534,0.566,0.607
				c0.085,0.075,0.389,0.211,0.303,0.053c-0.086-0.157-0.206-0.43-0.287-0.751c-0.081-0.322-0.143-0.766-0.213-0.962
				c-0.071-0.197-0.229-0.736-0.093-0.781c0.136-0.046,0.276,0.232,0.446,0.428c0.17,0.195,0.087,0.456,0.275,0.652
				c0.188,0.197,0.321,0.726,0.396,0.835c0.074,0.109,0.38,0.276,0.314,0.149c-0.066-0.125-0.165-0.884-0.129-1.075
				c0.037-0.189,0.027-0.306,0.016-0.699c-0.012-0.392-0.012-0.925,0.09-0.909c0.101,0.016,0.22,0.215,0.36,0.542
				c0.14,0.326,0.189,0.773,0.226,1.059c0.035,0.288-0.031,1.106,0.042,1.188c0.074,0.082,0.063-0.215,0.232-0.533
				c0.17-0.32,0.292-0.386,0.319-0.647s-0.053-0.644,0.101-0.812c0.152-0.168,0.396-0.154,0.396-0.154s0.129,0.532,0.086,0.875
				c-0.043,0.342-0.109,0.646-0.188,0.925c-0.076,0.276-0.339,0.498-0.317,0.646c0.021,0.147,0.146,0.591,0.24,0.359
				c0.097-0.229,0.402-0.501,0.513-0.676c0.109-0.176,0.358-0.515,0.445-0.53c0.086-0.017,0.111,0.502-0.096,0.974
				c-0.208,0.472-0.477,0.972-0.637,1.292C19.346,20.146,18.81,20.719,18.338,20.802"
			/>
			<path
				className="track"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.097,13.055c0.37-0.064,0.853-0.509,0.872-1
				c0.019-0.491-0.053-0.895-0.028-1.129c0.024-0.234-0.017-0.26,0.058-0.424c0.076-0.164,0.016-0.615,0.117-0.849
				c0.101-0.234,0.018-0.933,0.018-0.933s-0.347,0.529-0.389,0.698c-0.043,0.167-0.178,0.667-0.216,0.765
				c-0.038,0.099-0.214,0.325-0.203,0.145c0.011-0.181,0.011-0.48-0.034-0.818c-0.046-0.338-0.145-0.787-0.157-1
				c-0.013-0.214-0.072-0.786-0.186-0.789c-0.114-0.002-0.126,0.311-0.186,0.554c-0.061,0.243,0.084,0.471,0.011,0.719
				c-0.073,0.25,0.001,0.803-0.018,0.933c-0.019,0.13-0.188,0.385-0.18,0.242c0.007-0.142-0.167-0.91-0.255-1.083
				c-0.088-0.173-0.119-0.289-0.238-0.674c-0.118-0.385-0.292-0.902-0.36-0.856c-0.068,0.046-0.091,0.276-0.087,0.637
				s0.113,0.81,0.18,1.099c0.067,0.289,0.381,1.064,0.354,1.167c-0.027,0.102-0.116-0.189-0.343-0.447
				c-0.227-0.258-0.336-0.285-0.441-0.53c-0.104-0.246-0.17-0.642-0.336-0.758c-0.166-0.116-0.372-0.235-0.372-0.235
				s0.112,0.764,0.255,1.084c0.143,0.319,0.29,0.596,0.436,0.84c0.146,0.246,0.409,0.38,0.441,0.53
				c0.032,0.15,0.086,0.619-0.059,0.425c-0.144-0.195-0.456-0.364-0.592-0.5c-0.137-0.136-0.275-0.458-0.343-0.447
				c-0.068,0.01-0.073,0.59,0.231,0.984c0.305,0.395,0.662,0.798,0.883,1.061C13.149,12.727,13.726,13.12,14.097,13.055"
			/>
			<path
				className="track"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.726,5.928c-0.322-0.067-0.751-0.401-0.779-0.736
				c-0.028-0.334,0.024-0.604-0.002-0.765c-0.027-0.16,0.008-0.175-0.061-0.292c-0.069-0.116-0.029-0.418-0.122-0.583
				c-0.093-0.166-0.038-0.634-0.038-0.634s0.313,0.382,0.354,0.499s0.17,0.464,0.205,0.534c0.035,0.069,0.194,0.234,0.18,0.111
				c-0.014-0.123-0.021-0.327,0.01-0.553s0.106-0.525,0.112-0.668c0.005-0.144,0.043-0.529,0.142-0.523
				c0.099,0.006,0.117,0.219,0.175,0.388s-0.063,0.314,0.007,0.488c0.07,0.174,0.019,0.545,0.038,0.634
				c0.019,0.089,0.172,0.273,0.162,0.176c-0.01-0.097,0.123-0.607,0.195-0.719c0.073-0.111,0.097-0.188,0.19-0.442
				c0.093-0.253,0.23-0.594,0.291-0.558c0.061,0.036,0.085,0.193,0.091,0.438c0.005,0.244-0.078,0.542-0.129,0.734
				s-0.304,0.698-0.278,0.769s0.096-0.121,0.286-0.281c0.191-0.16,0.285-0.171,0.37-0.331c0.085-0.16,0.133-0.425,0.273-0.493
				c0.141-0.068,0.292,0.003,0.292,0.003s-0.056,0.373-0.171,0.581c-0.116,0.208-0.236,0.386-0.356,0.542
				c-0.12,0.157-0.346,0.231-0.37,0.332s-0.06,0.415,0.061,0.292c0.12-0.122,0.386-0.217,0.502-0.3c0.114-0.083,0.36-0.236,0.42-0.225
				c0.06,0.012-0.058,0.349-0.312,0.597s-0.554,0.499-0.739,0.662C16.538,5.767,16.048,5.996,15.726,5.928"
			/>
			<path
				className="leaf"
				d="M7.217,12.71C7.166,8.928,8.981,6.064,9.01,6.108c0,0-1.498,1.341-2.079,5.118c-4.804,1.243-1.19-2.13-1.622-2.09
				c0.292-0.045-3.593,3.393,1.57,2.469c-0.104,0.844-0.115,1.686,0.011,2.485c-3.47,4.753-3.168,0.456-3.168,0.456
				s-0.469,4.503,3.325-0.06c0.059,0.134,0.12,0.269,0.188,0.401c0.599,1.173,1.361,1.989,2,2.589
				c-5.623,6.307-5.432,0.591-5.432,0.591s-0.617,6.412,5.752-0.351c0.624,0.593,1.154,1.164,1.424,2.129
				c-4.98,5.688-5.081,2.331-5.051,1.598c0.007-0.079,0.012-0.128,0.008-0.131c0,0-0.005,0.05-0.008,0.131
				c-0.305,1.111,0.2,3.13,2.861,1.08c1.254-0.386-0.279,2.175-0.924,2.537c0.749-0.237,2.275-2.188,1.491-2.992
				c0.335-0.259,0.952-0.8,1.75-1.604c0.08,0.56,0.089,1.231,0.006,2.069c-1.046,2.198-2.232,2.267-2.65,3.453
				c-2.116,1.855-5.749-5.848-5.544-10.67C3.437,3.082,13.007,2.906,13.007,2.906s-6.756,4.674-1.683,9.622
				c2.342,2.284,5.549,6.204,2.604,7.832c-0.405,0.654-1.04,1.273-1.953,1.836c0.051-0.6,0.033-0.903-0.065-1.547
				c4.552-1.689,1.398-4.489,1.33-4.502c0.719,0.699,1.911,3.299-1.506,3.979c-0.121-0.563-0.501-1.283-1.068-1.971
				c3.214-1.311,1.363-4.128,1.093-4.544c-0.035-0.042-0.071-0.079-0.106-0.112l0.106,0.112c0,0,1.861,3.229-1.401,4.24
				c-0.418-0.443-0.966-0.968-1.69-1.625c4.041-1.982,1.841-3.951,1.841-3.951s1.831,1.802-1.993,3.741
				c-0.902-0.661-1.272-1.749-1.319-3.012c0,0,1.707-0.513,2.173-0.934c0.466-0.421,0-1.455,0-1.455s0.306,1.063-0.16,1.34
				C8.746,12.229,7.217,12.71,7.217,12.71"
			/>
			<path
				className="leaf"
				d="M25.135,15.516c0.949,3.661-0.133,6.875-0.172,6.838c0,0,1.136-1.659,0.802-5.465c4.371-2.35,1.663,1.786,2.073,1.644
				c-0.273,0.113,2.683-4.15-2.112-2.025c-0.1-0.844-0.29-1.663-0.603-2.411c2.241-5.442,2.969-1.195,2.969-1.195
				s-0.614-4.486-3.215,0.849c-0.089-0.117-0.181-0.233-0.277-0.345c-0.86-0.997-1.795-1.609-2.559-2.039
				C26.005,3.904,27.177,9.5,27.177,9.5s-0.925-6.374-5.504,1.708c-0.746-0.426-1.397-0.855-1.89-1.728
				c3.486-6.708,4.382-3.472,4.526-2.753c0.013,0.079,0.019,0.127,0.023,0.129c0,0-0.007-0.049-0.023-0.129
				c0.032-1.151-0.938-2.992-3.034-0.368c-1.128,0.673-0.247-2.179,0.293-2.684c-0.671,0.408-1.689,2.665-0.737,3.26
				c-0.264,0.332-0.734,1.004-1.318,1.976c-0.21-0.524-0.379-1.176-0.498-2.01c0.494-2.383,1.629-2.732,1.753-3.983
				c1.614-2.306,6.975,4.313,7.923,9.046c2.405,12.008-6.849,14.453-6.849,14.453s5.45-6.146-0.653-9.747
				c-2.818-1.662-6.865-4.706-4.393-6.988c0.238-0.732,0.707-1.484,1.461-2.248c0.094,0.595,0.183,0.885,0.431,1.487
				c-4.02,2.723-0.29,4.693-0.221,4.69c-0.864-0.508-2.641-2.752,0.516-4.223c0.252,0.517,0.793,1.126,1.507,1.659
				c-2.812,2.038-0.343,4.334,0.018,4.675c0.045,0.032,0.089,0.06,0.131,0.084l-0.131-0.084c0,0-2.575-2.695,0.354-4.452
				c0.511,0.331,1.168,0.712,2.027,1.178c-3.453,2.886-0.849,4.276-0.849,4.276s-2.206-1.316,1.047-4.108
				c1.033,0.427,1.65,1.397,1.997,2.612c0,0-1.537,0.904-1.89,1.423c-0.353,0.52,0.347,1.414,0.347,1.414s-0.551-0.961-0.163-1.339
				C23.764,16.347,25.135,15.516,25.135,15.516"
			/>
		</g>
	</svg>
);
