export default {
	apiGeoserverWFSProtocol: 'https',
	apiGeoserverWFSHost: 'geoinvaze.czu.cz',

	apiGeoserverWMSProtocol: 'https',
	apiGeoserverWMSHost: 'geoinvaze.czu.cz',

	apiBackendProtocol: 'https',
	apiBackendHost: 'geoinvaze.czu.cz',

	geoServerUrl: 'https://geoinvaze.czu.cz/geoserver/',

	serverUrl: 'https://geoinvaze.czu.cz/backend/',

	requestPageSize: 100
};
